import React, { useEffect } from 'react';

const SurveyPage: React.FC<any> = () => {
  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.location.replace(`https://aaacarolinas.tfaforms.net/61`);
    }
  }, []);

  return <></>;
};

export default SurveyPage;
